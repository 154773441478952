import React from "react";
import { Link } from "react-router-dom";
import "../../../../src/theme/css/HomePage.css";

export default function Home() {
  return (
    <>
      <div>
        <div className="banner banner-details">
          <div className="banner-all-details">
            <div className="text-width">
              <h4>
                AI Powered Digital Virtual Staff
                <span className="desktop-only"> - Tomorrow's Workforce, Today !!</span>
              </h4>

              <h6>Enabling frictionless journeys for enterprise</h6>
              <h6>&nbsp;</h6>

              <button className="button">
                <Link to="https://vimeo.com/1017598009"> Watch Demo </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
