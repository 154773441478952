import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUsMenu from "../components/pages/AboutUs/AboutUsMenu";
import PrivacyPolicy from "../components/pages/AboutUs/PrivacyPolicy";
import TermsAndConditions from "../components/pages/AboutUs/TermsnConditions";
import Blog from "../components/pages/Blog/Blog";
import BlogDetail from "../components/pages/Blog/BlogDetail";
import { BlogPage } from "../components/pages/Blog/BlogPage";
import { Contact } from "../components/pages/Contact/Contact";
import Home from "../components/pages/HomePage/Home";
import CareerCounsellor from "../components/pages/Portfolio/CareerCounsellor";
import ScrumMaster from "../components/pages/Portfolio/ScrumMaster";
import Service from "../components/pages/Service/Service";
import SignUp from "../components/SignUp/SignUp";


export default function Index() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/service" element={<Service />} />
        {/* <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/faq" element={<FAQ />} /> */}
        <Route path="/sm" element={<ScrumMaster />} />
        <Route path="/cc" element={<CareerCounsellor />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogpage" element={<BlogPage />} />
        <Route path="/blogDetail/:id" element={<BlogDetail />} />
        {/* <Route path="/blogpage" element={<BlogPage />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/aboutus" element={<AboutUsMenu />} />
        <Route path="/signin" element={<SignUp />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/termsofuse" element={<TermsAndConditions />} />

      </Routes>
    </div>
  );
}
