import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import blogcontain1 from "../../../assets/Images/Blog/blogcontain1.jpg";
import blogcontain2 from "../../../assets/Images/Blog/blogcontain2.jpg";
import blogcontain3 from "../../../assets/Images/Blog/blogcontain3.jpg";
import blogcontain4 from "../../../assets/Images/Blog/blogcontain4.jpg";
import blogcontain5 from "../../../assets/Images/Blog/blogcontain5.jpg";
import blogcontain6 from "../../../assets/Images/Blog/blogcontain6.jpg";
import blogcontain7 from "../../../assets/Images/Blog/blogcontain7.jpg";

export default function BlogDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const blogs = [
    {
      id: "0",
      title: "Why Choose a Digital Virtual Staff?",
      Heading: "Revolutionize Your Workforce with Digital Virtual Staff...",
      Date: "July 10, 2024",
      image: blogcontain1,
      details: "Highlight DVS's advantages: speed in role fulfillment, knowledge retention through AI, round-the-clock availability, and automation of repetitive tasks. Share real-world examples and data-backed benefits.",
    },
    {
      id: "1",
      title: "Streamlining Agile Workflows with S.JEEVES",
      Heading: "Transform Agile Ceremonies with AI-Powered Assistance...",
      Date: "May 10, 2024",
      image: blogcontain2,
      details: "Discuss S.JEEVES' integration with tools like JIRA, how it supports project leaders by automating admin work, and improves scrum team efficiency. Include testimonials and potential cost savings.",
    },
    {
      id: "2",
      title: "The Future of Career Counseling: LUMINO",
      Heading: "Empowering Students with AI-Driven Guidance...",
      Date: "Oct 10, 2024",
      image: blogcontain3,
      details: " Explain LUMINO’s role in personalized career advice, its conversational interface, and accessibility. Share success stories from students and parents.",
    },
    {
      id: "3",
      title: "Enhancing Investment Management with DVS Invest Assist",
      Heading: " Redefining Portfolio Management with AI...",
      Date: "Sept 10, 2024",
      image: blogcontain4,
      details: "Illustrate how DVS Invest Assist helps with scenario analysis, hyper-personalized campaigns, and relationship management. Provide practical examples of its application in finance.",
    },
    {
      id: "4",
      title: "Addressing Employee Burnout with Digital Staff",
      Heading: "How AI Solutions Support Work-Life Balance...",
      Date: "Sept 7, 2024",
      image: blogcontain5,
      details: " Explore how DVS Digital Staff reduces repetitive workloads, preventing burnout while ensuring consistent productivity. Compare this with traditional staff setups.",
    },
    {
      id: "5",
      title: "AI in Knowledge Retention and Organizational Growth",
      Heading: "Preserving Institutional Knowledge Through Digital Solutions...",
      Date: "Aug 7, 2024",
      image: blogcontain6,
      details: "Detail how AI captures and retains expertise to benefit organizations. Include comparisons with challenges faced in knowledge transfer in human-staffed environments.",
    },
    {
      id: "6",
      title: "24/7 Productivity with Digital Virtual Staff",
      Heading: "Uninterrupted Operations: The Edge of Digital Virtual Staff...",
      Date: "Aug 7, 2024",
      image: blogcontain7,
      details: "Explain the importance of 24/7 availability for global operations, offering case studies of companies that have leveraged such solutions.",
    },
  ];

  const blog = blogs.find((b) => b.id === id);

  useEffect(() => {
    if (!blog) {
      navigate("/blog"); // Redirect to the main blog page
    }
  }, [blog, navigate]);

  if (!blog) {
    return null; // Render nothing while redirection occurs
  }

  return (
    <Container className="my-5">
      <Row>
        <Col>
          <h1 className="Title">{blog.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col >
          <p className="heading">{blog.Heading}</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Image src={blog.image} fluid />
        </Col>
        <Col md={6}>
          <p className="Date">• {blog.Date}</p>
          <p className="details">{blog.details}</p>
        </Col>
      </Row>
    </Container>
  );
}
