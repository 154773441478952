import React from "react";
import Slider from "react-slick";

import MSHubLogo from "../../../assets/Images/BrandLogo/MSHubLogo.png";
import StartupIndiaLogo from "../../../assets/Images/BrandLogo/StartupIndia.png";
import AtlassianLogo from "../../../assets/Images/BrandLogo/Atalssian.png"
import MSHubLogo2 from "../../../assets/Images/BrandLogo/Microsoft-for-Startups.png"
import NVideaLogo from "../../../assets/Images/BrandLogo/nvidea.png"



function PeopleSay() {
  const settings = {
    autoplay: false,
    autoplaySpeed: 1500,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="spacing">
        <div className="peopleSayBgColor">

            <div className="titleFont text-center">
             <h2> Backed By</h2>
            </div>


             <div className="gallery">
                        <img src={NVideaLogo} alt="NVIDIA Inception" className="image" />
                        <img src={StartupIndiaLogo} alt="Start up India" className="image" />
                        <img src={MSHubLogo2} alt="Microsoft Start Hub Partner" className="image" />
                        <img src={AtlassianLogo} alt="Atlassian for Start up" className="image" />
             </div>





          </div>

      </div>
    </>
  );
}

export default PeopleSay;
