// import React from "react";
// import { Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import "../../../theme/css/Blog.css";
// import BlogData from "./BlogData";

// export default function Blog() {
//   return (
//     <>
//       <div className="otherPageBanner contactUsBanner">
//         <div className="text-center title">
//           <h2>LATEST BLOG</h2>
//           <h6>AI is the new UI</h6>
//         </div>
//       </div>
//       <div className="container">
//         <div className="spacing blogPage ">
//           <Row>
//             {BlogData.map((blogdata) => (
//               <Col xl={6} lg={6} md={6} sm={6}>
//                 <div className="blogCardMainClass blog">
//                   <div className="mainBlog " key={blogdata.id}>
//                     <div className="recent">
//                       <Link to="/blogpage">
//                         <img
//                           className="img-fluid mx-auto"
//                           variant="top"
//                           src={blogdata.image}
//                           alt="blog"
//                         />
//                         <div className="blog-post-hover">
//                           <div className="blog-icon">
//                             <div className="instaicon ">
//                               <i class="fa fa-search"></i>
//                             </div>
//                           </div>
//                         </div>
//                       </Link>
//                     </div>
//                     <div className="mainBlogBody">
//                       <h2> {blogdata.title} </h2>

//                       <div className="blogCardBody">
//                         <div className="d-inline ourBlogContain">
//                           <span className="blogdate">{blogdata.date}</span>
//                           <span className="d-inline ourBlogContainIcon">
//                             <i className="far fa-comment-dots"></i>
//                             {blogdata.comments}
//                           </span>
//                           <span className="d-inline ourBlogContainIcon">
//                             <i className="far fa-heart"></i>
//                             {blogdata.like}
//                           </span>
//                         </div>
//                       </div>
//                       <hr />
//                       <p className="paragraph"> {blogdata.contain} </p>{" "}
//                     </div>
//                   </div>
//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </div>
//       </div>
//     </>
//   );
// }


import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../theme/css/Blog.css";

import blogcontain1 from "../../../assets/Images/Blog/blogcontain1.jpg";
import blogcontain2 from "../../../assets/Images/Blog/blogcontain2.jpg";
import blogcontain3 from "../../../assets/Images/Blog/blogcontain3.jpg";
import blogcontain4 from "../../../assets/Images/Blog/blogcontain4.jpg";
import blogcontain5 from "../../../assets/Images/Blog/blogcontain5.jpg";
import blogcontain6 from "../../../assets/Images/Blog/blogcontain6.jpg";
import ContactUsBackgroundBanner from "../../../assets/Images/ContactUs/ContactUsBackgroundBanner.png";


export default function BlogDetail() {
  const blogs = [
    {
      id: '0',
      title: "Revolutionize Your Workforce with Digital Virtual Staff",
      Date: "July 10, 2024",
      image: blogcontain1,
    },
    {
      id: '1',
      title: "Transform Agile Ceremonies with AI-Powered Assistance",
      Date: "May 10, 2024",

      image: blogcontain2,
    },
    {
      id: '2',
      title: "Empowering Students with AI-Driven Guidance",
      Date: "Oct 10, 2024",
      image: blogcontain3,
    },
    {
      id: '3',
      title: "Redefining Portfolio Management with AI",
      Date: "Sept 10, 2024",
      image: blogcontain4,
    },
    {
      id: '4',
      title: "How AI Solutions Support Work-Life Balance",
      Date: "Sept 7, 2024",
      image: blogcontain5,
    },
    {
      id: '5',
      title: "Preserving Institutional Knowledge Through Digital Solutions",
      Date: "Aug 7, 2024",
      image: blogcontain6,
    },
    {
      id: '6',
      title: "Uninterrupted Operations: The Edge of Digital Virtual Staff",
      Date: "Aug 7, 2024",
      image: blogcontain6,
    },


  ];

  return (
    <Container>
      {/* Featured Blog Section */}
      <Row className="my-5">
        <Col lg={6}>
          <h1>How to Start a Blog in 2024: The Ultimate Guide</h1>
          <p className="lead">
            Learn step-by-step how to create and grow your blog into something impactful.
          </p>
          <Button className="Read-blogs">Read Blogs</Button>
        </Col>
        <Col lg={6}>
          <img
            src={ContactUsBackgroundBanner}
            alt="Featured Blog"
            className="img-fluid"
            style={{ width: "700px", height: "250px", objectFit: "cover" }}
          />
        </Col>
      </Row>

      {/* Other Blogs Section */}
      <Row>
        {blogs.map((blog, index) => (
          <Col lg={4} md={6} sm={12} key={index} className="mb-4">
            <Link to={`/blogDetail/${blog.id}`} style={{ textDecoration: "none", color: "inherit" }}>
              <Card>
                <Card.Img variant="top" src={blog.image} />
                <Card.Body>
                  <Card.Title>{blog.title}</Card.Title>
                  <Card.Text>• {blog.Date}</Card.Text>
                  <Link to={`/blogDetail/${blog.id}`} className="read-more">
                    <span className="text">Learn More</span>
                    <span className="icon">→</span>
                  </Link>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

