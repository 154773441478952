import React, { useEffect, useRef, useState } from "react";
import { Navbar, Nav, NavDropdown, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/DVS_transparent.png";
import BlackThemeLogo from "../../assets/Images/BrandLogo/DVS_transparent.png";
import { useAuth } from "../../AuthProvider";
import styles from '../../theme/css/Header.css';

const Header = (props) => {
  const { user, isAuthenticated, logout } = useAuth();
  const [addClass, setAddClass] = useState(" ");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogOutDropdown, setShowLogOutDropdown] = useState(false);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false); 

  const dropDownRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowLogOutDropdown(false);
    }
  };

  const handleLogoClick = () => {
    setShowLogOutDropdown(!showLogOutDropdown);
  };

  const handleLogout = () => {
    logout();
    setShowLogOutDropdown(false);
  };

  const scrollTrigger = 20;

  const onToggle = () => {
    setShowMobileSidebar(true); 
  };

  const onCloseNav = () => {
    setShowMobileSidebar(false); 
  };

  window.onscroll = function () {
    setAddClass();
    if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
      document.getElementsByTagName("nav")[0].classList.add("inverted");
    } else {
      document.getElementsByTagName("nav")[0].classList.remove("inverted");
    }
  };

  return (
    <>
      <div className={addClass}>
        <div className="header">
          <div className="sticky-top" id="stamenu">
            <Navbar expand="lg" collapseOnSelect>
              <div className="container">
                <div className="inner-header">
                  <Navbar.Brand href="/home">
                    <img src={ThemeLogo} alt="themeLogo" className="img-fluid mx-auto WLogo" />
                    <img src={BlackThemeLogo} alt="themeLogo" className="img-fluid mx-auto BLogo" />
                  </Navbar.Brand>

          
                  <Button
                    className="btn-navbar navbar-toggle"
                    onClick={onToggle} // Show the dialog for mobile
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </Button>
                  {/* Main navigation for desktop only */}
                  <div className="main-navigation d-none d-lg-block"> {/* Hidden on mobile */}
                    <Nav className="justify-content-center" style={{ flex:1 }}>
                      <Nav.Link href="home">
                        <Link to="/home">Home</Link>
                      </Nav.Link>
                      <Nav.Link href="home">
                        <Link to="/home">About Us</Link>
                      </Nav.Link>


                      <NavDropdown
                        title="Products"
                        id="collasible-nav-dropdown"
                        show={showDropdown}
                        onMouseLeave={() => setShowDropdown(false)}
                        onMouseOver={() => setShowDropdown(true)}
                      >
                        <NavDropdown.Item>
                          <Link to="/sm">S.JEEVES</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link to="/cc">LUMINO</Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </div>

              
                  <div className="contact-button d-none d-lg-block">
                    <button className="white-btn button">
                      <Link to="/contact"> Contact Us </Link>
                    </button>
                  </div>
                </div>
              </div>
            </Navbar>
          </div>
        </div>
      </div>

      <Modal
        show={showMobileSidebar}
        onHide={onCloseNav}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-top"
      >
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav className="flex-column">
            <Nav.Link href="home" onClick={onCloseNav}>
              <Link to="/home">Home</Link>
            </Nav.Link>
            <Nav.Link href="home" onClick={onCloseNav}>
              <Link to="/home">About Us</Link>
            </Nav.Link>
            <Nav.Link href="#contactus" onClick={onCloseNav}>
              <Link to="/contact">Contact Us</Link>
            </Nav.Link>

            <NavDropdown
              title="Products"
              id="collasible-nav-dropdown-mobile"
              show={showDropdown}
              onMouseLeave={() => setShowDropdown(false)}
              onMouseOver={() => setShowDropdown(true)}
            >
              <NavDropdown.Item onClick={onCloseNav}>
                <Link to="/sm">S.JEEVES</Link>
              </NavDropdown.Item>
              <NavDropdown.Item onClick={onCloseNav}>
                <Link to="/cc">LUMINO</Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
